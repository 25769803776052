@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400&family=Source+Sans+3:wght@300;400;700&Source+Serif+4:wght@300;400;500;600;700&display=swap');

html {
  font-family: sans-serif;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1;
}

body {
  padding: 0;
  margin: 0;
}
